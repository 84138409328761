import { getImage } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import VIEW_MORE from '../../static/post_view_more.svg';
import { css } from '@emotion/react';

export default function PostLink(props) {
  const {
    postTitle, // タイトル
    postUrl, // 遷移先情報
    postDate, // 投稿日
    category, // カテゴリー
    childImageSharp, // 画像情報（GatsbyImage）
    imageSrc, // 画像情報（microCMSなどのURL）
    type, // "column" or "news"
    postContentDigest,
  } = props;

  const image = useMemo(() => {
    if (childImageSharp) {
      return { type: 'gatsbyImage', data: getImage(childImageSharp) };
    }
    if (imageSrc) {
      return { type: 'img', data: imageSrc };
    }
    return null;
  }, [childImageSharp, imageSrc]);

  const cutBodyStringNum = (str) => {
    if (str.length >= 128) return str.slice(0, 125) + '...';
    return str;
  };

  const cutTitleStringNum = (str) => {
    if (str.length >= 36) return str.slice(0, 35) + '...';
    return str;
  };

  return (
    <LinkTag type={type} postUrl={postUrl}>
      <div css={itemStyle}>
        {image ? (
          // 画像データが ある 場合の表示
          <Image type={image.type} data={image.data} alt={postTitle} />
        ) : (
          // 画像データが ない 場合の表示
          <div css={thumbnailNoImageStyle}>no image</div>
        )}
        <div css={itemBodyStyle}>
          <time css={timeStyle}>{postDate}</time>
          <h2 css={titleStyle}>{cutTitleStringNum(postTitle)}</h2>
          <p css={descriptionStyle}>{cutBodyStringNum(postContentDigest)}</p>
          <div css={viewMoreStyle}>
            <Link to={`/news/${props.post.newsId}`} css={viewMoreLink}>
              <img src={VIEW_MORE} alt="" css={viewMoreImgStyle} />
            </Link>
          </div>
        </div>
      </div>
    </LinkTag>
  );
}

/** 動的にリンクタグを切り替える */
function LinkTag({ type, postUrl, children }) {
  switch (type) {
    case 'column':
      return (
        <a href={postUrl} target="_blank" rel="noreferrer noopener">
          {children}
        </a>
      );
    default:
      return <Link to={postUrl}>{children}</Link>;
  }
}

/** 最適な画像コンポーネントに置き換える */
function Image(props) {
  const { type, data, alt } = props;

  switch (type) {
    case 'gatsbyImage':
      return <GatsbyImage image={data} alt={alt} css={thumbnailStyle} />;
    default:
      return <img src={data} alt={alt} css={thumbnailStyle} />;
  }
}

const itemStyle = css`
  position: relative;
  width: 312px;
  height: 450px;
  padding: 16px 12px;
  background: #fff;
  border-radius: 16px;
`;

const thumbnailStyle = css`
  display: block;
  width: 280px;
  height: 157px;
  margin: auto;
  border-radius: 8px;
  object-fit: fill;
`;

const thumbnailNoImageStyle = css`
  width: 280px;
  height: 157px;
  background: #eee;
  border-radius: 8px;
  color: #999;
  line-height: 157px;
  object-fit: cover;
  text-align: center;
`;

const itemBodyStyle = css`
  padding-right: 4px;
  padding-left: 4px;
  margin-top: 20px;
  color: #3f4146;
`;

const timeStyle = css`
  color: #252a40;
  font-size: 8px;
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 1.375;
`;

const titleStyle = css`
  margin-top: 0;
  font-family: 'Noto Sans JP';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.4;
`;

const descriptionStyle = css`
  margin-top: 10px;
  font-family: 'Noto Sans JP';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: 1.5;
`;

const viewMoreStyle = css`
  position: absolute;
  right: 20px;
  bottom: 20px;
  height: 10px;
`;

const viewMoreImgStyle = css`
  width: 92px;
  height: 17px;
`;

const viewMoreLink = css`
  color: #252a40;
  font-size: 12px;
  font-weight: bold;
`;

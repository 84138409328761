import React, { useMemo, useState } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { css } from '@emotion/react';
import { mediaPc } from '../styles/media-query';
import ALLOW from '../../static/arrow.svg';
import classNames from 'classnames';

export default function NewsCategoriesSelectBox(props) {
  const { currentCategory } = props;

  const [selectedCategory, setSelectedCategory] = useState(
    currentCategory || { title: 'ALL', slug: 'ALL' }
  );
  const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState(false);

  const { allMicrocmsNews } = useStaticQuery(graphql`
    query {
      allMicrocmsNews {
        nodes {
          category {
            slug
            title
          }
        }
      }
    }
  `);

  const onChangeSelectedCategory = (category) => {
    if (category.slug === 'ALL') return navigate('/news/');
    navigate(`/news/category/${category.slug}/`);
  };

  const categories = useMemo(() => {
    const slugs = [
      ...new Set(allMicrocmsNews.nodes.map((node) => node.category.slug)),
    ];
    return slugs.map((slug) => {
      const target = allMicrocmsNews.nodes.find(
        (node) => node.category.slug === slug
      );
      return target ? target.category : {};
    });
  }, [allMicrocmsNews.nodes]);

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <button
        type="button"
        onClick={() => {
          if (!isOpenDropdownMenu) return setIsOpenDropdownMenu(true);
          onChangeSelectedCategory(selectedCategory);
          setIsOpenDropdownMenu(false);
        }}
        css={css`
          margin-top: 50px;
          width: 231px;
          height: 42px;
          border-radius: 25px;
          border: none;
          box-shadow: 0px 4px 8px #e4e4e4;
          text-align: center;
          font-size: 15px;
          font-weight: 900;
          color: #252a40;
          font-family: 'Noto Sans JP';
          padding: 8px;
          background: #ffffff;
          position: relative;

          ${isOpenDropdownMenu &&
          `
            box-shadow: 0px 0px 0px #e4e4e4;
            border-radius: 25px 25px 0 0;
            border-bottom: 1px solid #F0F2F5;
          `}

          ${mediaPc} {
            display: none;
          }
        `}
      >
        {selectedCategory.title}
        <img
          src={ALLOW}
          alt=""
          css={css`
            position: absolute;
            top: 15px;
            right: 20px;
            transform: ${isOpenDropdownMenu
              ? 'rotate(90deg)'
              : 'rotate(270deg)'};
          `}
        />
      </button>

      <div
        css={css`
          opacity: 0;
          transition: opacity 0.3s ease-out;
          position: absolute;
          top: 92px;
          z-index: 1000;
          ${isOpenDropdownMenu && 'opacity: 1;'}
        `}
      >
        {categories.length > 0 &&
          categories.map((category, i) => {
            return (
              <div
                key={category.slug}
                onClick={() => {
                  setSelectedCategory(
                    selectedCategory.slug === category.slug
                      ? { title: 'ALL', slug: 'ALL' }
                      : category
                  );
                  setIsOpenDropdownMenu(false);
                  onChangeSelectedCategory(
                    selectedCategory.slug === category.slug
                      ? { title: 'ALL', slug: 'ALL' }
                      : category
                  );
                }}
                css={css`
                  background-color: #fff;
                  width: 231px;
                  height: 42px;
                  text-align: center;
                  font-size: 15px;
                  font-weight: 900;
                  letter-spacing: 0.1em;
                  line-height: 1.4;
                  font-family: 'Noto Sans JP';
                  padding: 8px;
                  color: #252a40;
                  border-bottom: 1px solid #f0f2f5;
                  transition: none;

                  ${i + 1 === categories.length &&
                  `
                        border-radius: 0 0 25px 25px;
                        border: none;
                        box-shadow: 0px 4px 8px #e4e4e4;
                      `}
                `}
              >
                {selectedCategory.slug === category.slug
                  ? 'ALL'
                  : category.title}
              </div>
            );
          })}
      </div>
    </div>
  );
}

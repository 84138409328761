import React from 'react';
import { Link } from 'gatsby';
import * as Styles from '../styles/_pagination.module.scss';
import classNames from 'classnames';
import { css } from '@emotion/react';
import NextAllow from '../../static/next-allow.svg';
import PrevAllow from '../../static/prev-allow.svg';

export default function Pagination(props) {
  const {
    currentPageNum = 1, // 現在のページ
    pagination = [], // ページ一覧情報
    prevPagePath = null, // 前のページの情報
    nextPagePath = null, // 次のページの情報
  } = props;

  return (
    <>
      <div
        css={css`
          font-size: 1.4rem;
          position: relative;
          width: 215px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
        `}
      >
        {prevPagePath && (
          <Link
            to={prevPagePath}
            css={css`
              margin-right: 27px;
            `}
          >
            <img src={PrevAllow} alt="" />
          </Link>
        )}
        <ul
          css={css`
            padding: 0;
            display: flex;
            justify-content: center;
            margin-bottom: 0;
            gap: 0 18px;
          `}
        >
          {pagination.map((v) => (
            <li key={v.pageNum}>
              <Link
                to={v.path}
                css={css`
                  display: inline-block;
                  border: none;
                  width: 32px;
                  height: 32px;
                  text-align: center;
                  color: #252a40;
                  font-size: 22px;
                  font-weight: 900;
                  font-family: 'Noto Sans JP';
                  letter-spacing: 0.1em;
                  line-height: 1.45;

                  ${currentPageNum === v.pageNum &&
                  `
                    @extend .pagination__link;
                    color: #6C6F7E;
                    transition: background-color 0.1s;
                  `}
                `}
              >
                {v.pageNum}
              </Link>
            </li>
          ))}
        </ul>
        {nextPagePath && (
          <Link
            to={nextPagePath}
            css={css`
              margin-left: 27px;
            `}
          >
            <img src={NextAllow} alt="" />
          </Link>
        )}
      </div>
    </>
  );
}

import React, { useMemo } from 'react';
import Layout from '../components/Layout';
import Seo from '../components/seo';
import PostLink from '../components/PostLink';
import NewsCategories from '../components/news-categories';
import Pagination from '../components/pagination';
import Title from '../components/Title';
import * as Styles from '../styles/pages/_news.module.scss';
import NewsPageWrapper from '../components/news-page-wraper';
import NewsCategoriesSelectBox from '../components/news-categories-selectbox';
import BackButton from '../components/back-button';
import { SectionTitle } from '../components/SectionTitle';
import { css } from '@emotion/react';

export default function News({ pageContext }) {
  const {
    nodes,
    category,
    prevPagePath,
    nextPagePath,
    pagination,
    currentPageNum,
    totalPageNum,
    // totalCount,
  } = pageContext;

  const breadcrumb = [{ label: 'お知らせ', url: '/contact' }];

  /** 2ページ以上ページネーションが存在すれば true */
  const isPageNation = useMemo(() => totalPageNum > 1, [totalPageNum]);

  const formatPublishDate = (dateStr) => {
    return dateStr.split('/').join('.');
  };
  return (
    <>
      <Seo
        url={category ? `/news/category/${category.slug}` : `/news`}
        title="ニュース｜Uniforce（ユニフォース）株式会社"
        description="Uniforceに関する情報・お客様へのお知らせやIPOに関するセミナーなどのお知らせを掲載しています。"
        image="https://uniforce.co.jp/og-image.jpg"
        breadcrumbs={breadcrumb}
      />
      <Layout breadcrumb={breadcrumb}>
        <NewsPageWrapper>
          <div className={Styles.news_page}>
            <div className={Styles.title__wrapper}>
              <SectionTitle
                enText="News"
                titleText="お知らせ"
                css={css`
                  position: relative;
                  padding-top: 140px;
                  z-index: 2;
                  max-width: 1158px;
                  margin: auto;
                  @media (max-width: 1020px) {
                    margin-left: -35px;
                  }
                `}
              />
            </div>
            {/* カテゴリ一覧 */}
            <NewsCategories currentSlug={category && category.slug} />
            <div className={Styles.categories__select__wrapper}>
              <NewsCategoriesSelectBox
                currentCategory={category || { title: 'ALL', slug: 'ALL' }}
              />
            </div>

            {/* ニュース一覧 */}
            <ul className={Styles.news_page__list}>
              {nodes.map((node) => (
                <li key={node.newsId}>
                  <PostLink
                    post={node}
                    postTitle={node.title}
                    postUrl={`/news/${node.newsId}/`}
                    postContentDigest={node.body.replace(/(<([^>]+)>)/gi, '')}
                    postDate={formatPublishDate(node.publishedAt)}
                    category={node.category.title}
                    imageSrc={node.thumbnail ? node.thumbnail.url : null}
                    type="news"
                  />
                </li>
              ))}
            </ul>

            {/* pagination */}
            {isPageNation && (
              <Pagination
                currentPageNum={currentPageNum}
                pagination={pagination}
                prevPagePath={prevPagePath}
                nextPagePath={nextPagePath}
              />
            )}

            <div className={Styles.button__wrapper}>
              <div className={Styles.news__back_btn}>
                <BackButton path="/" />
              </div>
            </div>
          </div>
        </NewsPageWrapper>
      </Layout>
    </>
  );
}

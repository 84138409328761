import React, { useMemo } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import * as Styles from '../styles/_news-categories.module.scss';
import classNames from 'classnames';

export default function NewsCategories(props) {
  const { currentSlug } = props;

  const categoryLinkClassName = (slug) => {
    const isCurrent = currentSlug === slug;
    return classNames(Styles.news_categories__link, {
      [Styles.news_categories__link_active]: isCurrent,
    });
  };

  const { allMicrocmsNews } = useStaticQuery(graphql`
    query {
      allMicrocmsNews {
        nodes {
          category {
            slug
            title
          }
        }
      }
    }
  `);

  const categories = useMemo(() => {
    const slugs = [
      ...new Set(allMicrocmsNews.nodes.map((node) => node.category.slug)),
    ];
    return slugs.map((slug) => {
      const target = allMicrocmsNews.nodes.find(
        (node) => node.category.slug === slug
      );
      return target ? target.category : {};
    });
  }, [allMicrocmsNews.nodes]);

  return (
    <ul className={Styles.news_categories__list}>
      <li>
        <Link to="/news/" className={categoryLinkClassName()}>
          ALL
        </Link>
      </li>
      {categories.map((category) => (
        <li key={category.slug}>
          <Link
            to={`/news/category/${category.slug}/`}
            className={categoryLinkClassName(category.slug)}
          >
            {category.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}
